// import Swiper from "swiper";
// import { Navigation, Pagination } from "swiper/modules";
import Swiper from "swiper/bundle";
import { throttle } from "throttle-debounce";

export function recruit() {
	if (document.querySelector(".p-recruit")) {
		const header = document.querySelector(".p-recruit-header");
		const drawernavBtn = document.querySelector(".p-recruit-nav_bar");
		const drawernav = document.querySelector(".p-recruit-drawernav");
		const drawernavBg = document.querySelector(".p-recruit-drawernav_bg");

		if (drawernavBtn) {
			drawernavBtn.addEventListener("click", function (e) {
				e.preventDefault();
				drawernavBg.classList.toggle("_active");
				setTimeout(() => {
					header.classList.toggle("_active");
					drawernav.classList.toggle("_active");
					drawernavBtn.classList.toggle("_active");
				}, 400);
				setTimeout(() => {
					drawernavBg.classList.toggle("_active");
				}, 500);
			});
		}

		//

		window.addEventListener(
			"scroll",
			throttle(100, () => {
				getHeader();
				parallaxBg();
				parallaxText();
			}),
			false
		);

		const recruitHeader = document.querySelector(".p-recruit-header");
		let recruitPHeader;
		if (document.querySelector(".p-recruit-home")) {
			recruitPHeader = document.querySelector(".p-recruit-home_mv");
		} else {
			recruitPHeader = document.querySelector(".p-recruit-p_header");
		}
		const getHeader = () => {
			const position = Math.floor(window.innerHeight * 0);
			if (recruitPHeader) {
				let clientRect = recruitPHeader.getBoundingClientRect();
				let offsetTop = Math.floor(clientRect.top);
				let offsetBottom = Math.floor(clientRect.bottom);
				if (offsetBottom < position) {
					recruitHeader.classList.add("_scroll");
				} else {
					recruitHeader.classList.remove("_scroll");
				}
			}
		};

		const parallax = document.querySelectorAll(".parallax_bg");

		const parallaxBg = () => {
			for (let t = 0; t < parallax.length; t++) {
				let clientRect = parallax[t].getBoundingClientRect();
				let y = clientRect.top;
				let screenPosition = window.innerHeight;
				const rect = parallax[t].getBoundingClientRect();

				let scrollPosition = window.pageYOffset;
				parallax[t].style.transform =
					"rotate(-45deg) translate(-" +
					scrollPosition * 0.05 +
					"px," +
					scrollPosition * 0.05 +
					"px)";
				if (rect.top >= 0) {
				}
			}
		};

		//
		const parallaxText = () => {
			const scrollingText = document.querySelector(".voice_label");
			if (scrollingText) {
				let clientRect = scrollingText.getBoundingClientRect();
				let y = clientRect.top;
				let num = window.innerHeight + 200;
				let scrollPosition = num - y;

				if (y <= num) {
					// console.log(y, num);
					scrollingText.style.transform =
						"translateX(-" + scrollPosition * 0.3 + "px)";
				}
			}
		};
	}

	if (document.querySelector(".p-recruit-home")) {
		document.addEventListener("DOMContentLoaded", () => {
			const elm = document.querySelectorAll("._animation");
			for (let t = 0; t < elm.length; t++) {
				const str = elm[t].innerHTML;
				let output = "";
				let isInsideBr = false;
				for (let i = 0; i < str.length; i++) {
					if (str[i] === "<" || str.slice(i, i + 4) === "<br") {
						isInsideBr = true;
						// console.log(str.slice(i, i + 19));
						if (str.slice(i, i + 19) == '<br class="sponly">') {
							output += '<br class="sponly">';
							// console.log("a");
							i += 18;
						} else {
							output += "<br>";
							i += 3;
						}
					} else {
						output += "<span>" + str[i] + "</span>";
					}
				}
				// console.log(output);
				elm[t].innerHTML = output;
			}
			const photo = document.querySelectorAll(".photo_mv");
			setTimeout(() => {
				for (let t = 0; t < photo.length; t++) {
					photo[t].classList.add("_active");
				}
			}, 100);
			document.querySelector(".photo_mv").addEventListener("transitionend", () => {
				setTimeout(() => {
					for (let t = 0; t < elm.length; t++) {
						elm[t].classList.add("_active");
					}
					document.querySelector(".line").classList.add("_active");
				}, 500);
			});
			document.querySelector(".line").addEventListener("transitionend", () => {
				document.querySelector(".copy").classList.add("_end");
			});
		});

		window.addEventListener(
			"DOMContentLoaded",
			() => {
				const options = {
					slidesPerView: "auto",
				};
				const breakPoint = 768;
				let swiper;
				if (window.innerWidth <= breakPoint) {
					swiper = new Swiper(".swiper", options);
				} else {
					swiper = undefined;
				}
				window.addEventListener(
					"resize",
					() => {
						if (window.innerWidth <= breakPoint) {
							if (swiper) return;
							swiper = new Swiper(".swiper", options);
						} else {
							if (!swiper) return;
							swiper.destroy();
							swiper = undefined;
						}
					},
					false
				);
			},
			false
		);
	}

	if (document.querySelector(".p-recruit-voice")) {
		window.addEventListener(
			"DOMContentLoaded",
			() => {
				const options = {
					slidesPerView: "auto",
				};
				const breakPoint = 768;
				let swiper;
				if (window.innerWidth <= breakPoint) {
					swiper = new Swiper(".swiper", options);
				} else {
					swiper = undefined;
				}
				window.addEventListener(
					"resize",
					() => {
						if (window.innerWidth <= breakPoint) {
							if (swiper) return;
							swiper = new Swiper(".swiper", options);
						} else {
							if (!swiper) return;
							swiper.destroy();
							swiper = undefined;
						}
					},
					false
				);
			},
			false
		);
	}

	if (document.querySelector(".p-recruit-voice_header")) {
		window.addEventListener(
			"DOMContentLoaded",
			() => {
				setTimeout(() => {
					document.querySelector(".p-recruit-voice_header").classList.add("_active");
				}, 200);
			},
			false
		);
	}

	if (document.querySelector(".p-recruit-center_header")) {
		const overlay = document.querySelector(".overlay");
		const open_click = document.querySelector(".open_click");
		/*
		setTimeout(() => {
			document.querySelector(".first").classList.add("loaded");
			document.querySelector(".click").classList.add("loaded");
		}, 2000);
		*/
		open_click.addEventListener("click", function () {
			document.querySelector(".first").classList.add("loaded");
			document.querySelector(".click").classList.add("loaded");
			document.querySelector(".open_click").classList.add("loaded");
		});

		const popupItem = document.querySelectorAll(".popup");
		const closeItem = document.querySelectorAll(".close");
		const swiper = new Swiper(".swiper", {
			loop: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
		popupItem.forEach((element) => {
			element.addEventListener("click", function () {
				// console.log(element.id);
				overlay.classList.add("_js-modal");
				document
					.querySelector("#" + element.id + "modal")
					.classList.add("_is-active");
			});
		});

		closeItem.forEach((element) => {
			element.addEventListener("click", function (e) {
				e.preventDefault();
				this.parentElement.classList.remove("_is-active");
				overlay.classList.remove("_js-modal");
			});
		});
	}
}
