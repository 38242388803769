export function business() {
	if (document.querySelector(".p-business")) {
		const modalBtn = document.querySelectorAll(".modal");
		const modalBox = document.querySelector(".modal_container");
		const modalClose = document.querySelector(".modal_container .close");
		modalBtn.forEach((element) => {
			element.addEventListener("click", function (e) {
				e.preventDefault();
				const src = element.dataset.src;
				const title = element.dataset.title;
				modalBox.querySelector(".title").innerHTML = title;
				modalBox.querySelector(".photo").innerHTML = '<img src="' + src + '">';
				modalBox.classList.add("_active");
				setTimeout(() => {
					modalBox.querySelector(".content").classList.add("_active");
				}, 300);
			});
		});
		modalBox.addEventListener("click", function () {
			modalBox.classList.remove("_active");
			setTimeout(() => {
				modalBox.querySelector(".title").innerHTML = "";
				modalBox.querySelector(".photo").innerHTML = "";
			}, 300);
		});
	}
}
